<template>
  <div ref="carousel" class="dp-top-pro-recipe-carousel">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api';

// @see https://swiperjs.com/
import Swiper from 'swiper';
import lodash from 'lodash';

export default defineComponent({
  props: {
    options: {
      type: Object,
      default: null
    }
  },

  setup: (props) => {
    const carousel = ref(null);
    const pagination = ref(null);
    const swiper = ref(null);

    const init = () => {
      const initOptions = {
        slidesPerView: '1.73',
        spaceBetween: 16
      };
      const mergedOptions = lodash.merge(initOptions, props.options);
      swiper.value = new Swiper(carousel.value, mergedOptions);
    };

    onMounted(async () => {
      init();
    });

    return {
      carousel,
      pagination
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top-pro-recipe-carousel::v-deep {
  position: relative;
  margin: 0 0 28px;

  .swiper-slide {
    display: grid;
    grid-template-rows: 1fr;
    height: auto;
  }
}
</style>
